<template>
    <div class="wrapper1">
        <div class="DemoText">{{ propText }}</div>
        <div class="close1" @click="close"></div>
        <div class="block1">
            <div class="clickText" id="clickText">点击录音</div>
            <!-- 动画 -->
            <div class="animation">
                <div class="ani1" @click="start">
                    <img src="../CommonAsset/img/cc.png" />
                </div>
                <div class="ani2" v-show="show"></div>
                <div class="ani3" v-show="show"></div>
            </div>
            <!-- 字幕框 -->
            <div class="SubtitleBox" id="recognize_result_box"></div>
        </div>
    </div>
</template>

<script>
import { reactive, toRefs, ref } from "vue";
import "../CommonAsset/js/speech/jquery.js";
import "../CommonAsset/js/speech/cryptojs.js";
import { signCallback } from "../CommonAsset/js/speech/asrauthentication.js";
import "../CommonAsset/js/speech/config.js";
import "../CommonAsset/js/speech/webaudiospeechrecognizer.js";
// import '../CommonAsset/js/speech/index.js';
// 下面是 语音识别 讯飞 2024-11-21
// import "../CommonAsset/js/iat/crypto-js.js";
// import "../CommonAsset/js/iat/dist/index.umd.js";
// import "../CommonAsset/js/iat/index.js";

export default {
    emits: ["close"],
    props: {
        propText: "",
    },
    setup(props, context) {
        var webAudioSpeechRecognizer = null;
        const params = {
            signCallback: signCallback, // 鉴权函数
            // 用户参数
            secretid: config.secretId,
            appid: config.appId,
            // 实时识别接口参数
            engine_model_type: "16k_zh", // 因为内置WebRecorder采样16k的数据，所以参数 engineModelType 需要选择16k的引擎，为 '16k_zh'
            // 以下为非必填参数，可跟据业务自行修改
            voice_format: 1,
            hotword_id: "08003a00000000000000000000000000",
            needvad: 1,
            filter_dirty: 1,
            filter_modal: 2,
            filter_punc: 0,
            convert_num_mode: 1,
            word_info: 2,
        };
        const close = () => {
            var resultword = document.getElementById("recognize_result_box");
            resultword.innerHTML = "";
            context.emit("close");
        };
        const state = reactive({
            show: false,
        });
        // 开始录音
        const start = () => {
            var resultword = document.getElementById("recognize_result_box");
            var clickText = document.getElementById("clickText");
            webAudioSpeechRecognizer = new WebAudioSpeechRecognizer(params);

            // 开始识别
            webAudioSpeechRecognizer.OnRecognitionStart = (res) => {
                console.log("开始识别", res);
                //window.location.href = "uniwebview://start recognize... "+res;
                state.show = true;
                setTimeout(function(){
                  state.show = false;
                  webAudioSpeechRecognizer.stop();
                }, 3000);
                resultword.innerHTML = "";
                clickText.innerHTML = "<span style='color:green'>正在录音</span>";
            };
            // 一句话开始
            webAudioSpeechRecognizer.OnSentenceBegin = (res) => {
                console.log("一句话开始", res);
                //window.location.href = "uniwebview://start a word... "+res;
            };
            // 识别变化时
            webAudioSpeechRecognizer.OnRecognitionResultChange = (res) => {
                console.log("识别变化时", res);
                //window.location.href = "uniwebview://changing recognize... "+res;
                resultword.innerHTML += res.result.voice_text_str;
            };
            // 一句话结束
            webAudioSpeechRecognizer.OnSentenceEnd = (res) => {
                console.log("一句话结束", res);
                //window.location.href = "uniwebview://end a word... "+res;
                resultword.innerHTML = res.result.voice_text_str;
            };
            // 识别结束
            webAudioSpeechRecognizer.OnRecognitionComplete = (res) => {
                console.log("识别结束", res);
                //window.location.href = "uniwebview://end recognize... "+res;
                var orginword = props.propText;
                var resultText = resultword.innerHTML;
                orginword = orginword.replace(
                    /[\ |\~|\`|\!|\@|\#|\$|\%|\^|\&|\*|\(|\)|\-|\_|\+|\=|\||\\|\[|\]|\{|\}|\;|\:|\"|\'|\,|\<|\.|\>|\/|\?|\？|\。]/g,
                    ""
                );
                resultText = resultText.replace(
                    /[\ |\~|\`|\!|\@|\#|\$|\%|\^|\&|\*|\(|\)|\-|\_|\+|\=|\||\\|\[|\]|\{|\}|\;|\:|\"|\'|\,|\<|\.|\>|\/|\?|\？|\。]/g,
                    ""
                );
                resultword.innerHTML = "";
                if (orginword == resultText) {
                    resultword.innerHTML =
                        "<span style='color:green'>" + resultText + "</span>";
                } else {
                    for (var i = 0; i < orginword.length; i++) {
                        if (resultText[i] != undefined) {
                            if (orginword[i] == resultText[i]) {
                                resultword.innerHTML +=
                                    "<span style='color:green'>" +
                                    resultText[i] +
                                    "</span>";
                            } else {
                                resultword.innerHTML +=
                                    "<span style='color:red'>" +
                                    resultText[i] +
                                    "</span>";
                            }
                        }
                    }
                }
                clickText.innerHTML = "点击录音";
            };
            // 识别错误
            webAudioSpeechRecognizer.OnError = (res) => {
                console.log("识别失败", res);
            };
            webAudioSpeechRecognizer.start();
        };
        return {
            close,
            ...toRefs(state),
            start,
        };
    },
};
</script>

<style scoped>
.wrapper1 {
    position: relative;
    width: 100%;
    height: 100%;
}

.block1 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80vw;
    height: 45vh;
    background-color: #fefef8;
    border: 8px solid #46a923;
    border-radius: 3px;
}
.close1 {
    position: absolute;
    top: 25%;
    right: 7%;
    width: 39px;
    height: 39px;
    background: url("../CommonAsset/img/close.png");
    z-index: 10;
}
.DemoText {
    position: relative;
    top: 23%;
    left: 12%;
    width: 35vw;
    height: 10vh;
    font-size: 25px;
    text-align: center;
    color: white;
    background-color: #46a923;
    border-radius: 10px 10px 0 0;
}
.clickText {
    width: 100%;
    height: 6vh;
    line-height: 6vh;
    font-size: 18px;
    margin-top: 10%;
    text-align: center;
    color: #c7c7c7;
}
.animation {
    position: relative;
    height: 13vh;
}
.ani1 {
    width: 115px;
    height: 115px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}
.ani2 {
    width: 115px;
    height: 115px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    background: url("../CommonAsset/img/aa.png");
    animation: move 3s steps(12, end) infinite;
}
.ani3 {
    width: 115px;
    height: 115px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    background: url("../CommonAsset/img/bb.png");
    animation: move2 1s steps(5, end) infinite;
}
.SubtitleBox {
    position: relative;
    width: 80%;
    height: 10vh;
    margin: 12% auto 0;
    background-color: #f7f7f7;
    border: 1px solid #bcbcbc;
    font-size: 25px;
    font-family: "宋体";
}
@keyframes move {
    0% {
        background-position: 0 0;
    }

    100% {
        background-position: -1404px 0;
    }
}
@keyframes move2 {
    0% {
        background-position: 0 0;
    }

    100% {
        background-position: -585px 0;
    }
}
</style>
