<template>
  <div class="wrapper">
    <div class="OutBox">
      <div class="topWordBox">
        <div
          class="topWord"
          v-for="(item, index) in text"
          :class="{ alive: isActive == index }"
          :key="index"
          @click="sendWord(index)"
        >
          {{ item }}
        </div>
      </div>
      <div class="block">
        <div class="close" @click="close"></div>
        <div class="word" ref="box"></div>
        <div class="box">
          <van-button style="width: 100px" type="success" @click="Demo"
            >演示</van-button
          >
          <van-button style="width: 100px" type="success" @click="write"
            >书写</van-button
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { reactive, toRefs, ref, onMounted } from "vue";
import HanziWriter from "hanzi-writer";
export default {
  emits: ["close"],
  props: {
    propText: "",
  },
  setup(props, context) {
    let size;
    if (screen.width < 380) {
      size = 220;
    } else {
      size = 250;
    }
    let WriteText = props.propText;
    let TextArr = WriteText.split("");
    const state = reactive({
      text: TextArr,
      isActive: 0,
    });

    const box = ref();
    const close = () => {
      context.emit("close");
    };
    let sendWord = (item_index) => {
      state.isActive = item_index;
      box.value.innerHTML = "";
      wenzi(item_index);
    };
    let word;
    // 演示
    let Demo = () => {
      word.animateCharacter();
    };
    //  书写
    let write = () => {
      word.quiz();
    };
    // 渲染文字
    let wenzi = (index = 0) => {
      word = HanziWriter.create(box.value, state.text[index], {
        width: size,
        height: size,
        padding: 5,
      });
    };
    onMounted(() => {
      wenzi();
    });
    return {
      ...toRefs(state),
      box,
      close,
      Demo,
      write,
      sendWord,
    };
  },
};
</script>

<style scoped>
.wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.block {
  position: relative;
  width: 80vw;
  height: 430px;
  background-color: #fefef8;
  border: 8px solid #87d96a;
  border-radius: 3px;
  z-index: 9;
}
.close {
  position: absolute;
  top: -6%;
  right: -5%;
  width: 39px;
  height: 39px;
  background: url("../CommonAsset/img/close.png");
  z-index: 10;
}
.box {
  width: 235px;
  margin: 5% auto 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media screen and (max-width: 380px) {
  .word {
    width: 220px;
    margin: 15% auto 0;
    border: 5px dashed #b82a09;
  }
}
@media screen and (min-width: 381px) {
  .word {
    width: 250px;
    margin: 15% auto 0;
    border: 5px dashed #b82a09;
  }
}
.beijing {
  width: 150px;
  height: 150px;
}
.topWordBox {
  display: flex;
  width: 80vw;
}
.topWord {
  width: 60px;
  line-height: 60px;
  text-align: center;
  font-size: 32px;
  height: 100%;
  color: white;
  margin-left: 1%;
  border-radius: 25% 25% 0 0;
  background-color: #46a923;
}

.alive {
  font-weight: bold;
  font-size: 40px;
  background-color: #87d96a;
  color: #db3419;
}
.OutBox {
  position: relative;
}
</style>
