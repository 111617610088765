<template>
    <div v-cloak>
        <div id="app">
            <img
                src="https://lz06-1301325388.cos.ap-chengdu.myqcloud.com/2022xiongmao/CommonAsset/img/home/BG.png"
            />
        </div>
        <!-- 头部 -->
        <div class="header" style="display: none">
            <!-- 语言修改 -->
            <div class="languageChange">
                <el-dropdown @command="handleCommand" size="small">
                    <el-button type="primary" size="small"
                        >{{ changeLanguage }}&nbsp;
                        <el-icon class="el-icon--right"
                            ><svg
                                t="1641818630955"
                                class="icon"
                                viewBox="0 0 1024 1024"
                                version="1.1"
                                xmlns="http://www.w3.org/2000/svg"
                                p-id="2112"
                                width="16"
                                height="16"
                            >
                                <path
                                    d="M966.4 323.2c-9.6-9.6-25.6-9.6-35.2 0l-416 416-425.6-416c-9.6-9.6-25.6-9.6-35.2 0-9.6 9.6-9.6 25.6 0 35.2l441.6 432c9.6 9.6 25.6 9.6 35.2 0l435.2-432C976 345.6 976 332.8 966.4 323.2z"
                                    p-id="2113"
                                    fill="#ffffff"
                                ></path></svg
                        ></el-icon>
                    </el-button>
                    <template #dropdown>
                        <el-dropdown-menu>
                            <el-dropdown-item
                                v-for="(item, key) in dataItem.language"
                                :key="key"
                                :command="key"
                            >
                                {{ item }}
                            </el-dropdown-item>
                        </el-dropdown-menu>
                    </template>
                </el-dropdown>
            </div>
        </div>
        <!-- warning的弹出框 -->
        <div v-if="warningtitle" class="warningTitle">
            <div class="warningContent">
                <div class="cancel" @click="warning">
                    <img
                        src="https://lz06-1301325388.cos.ap-chengdu.myqcloud.com/2022xiongmao/CommonAsset/img/home/叉叉.png"
                        alt=""
                    />
                </div>
                <div class="warnText">
                    您还可以下载并安装《大熊猫和他的伙伴们》专属APP，扫描书上的主题图片，通过熊猫“胖大福”的视角讲述其丰富多彩的森林生活，身临其境地走近大熊猫、走近大自然。
                </div>
                <div class="goDownload" @click="goDownload">
                    <img
                        src="https://lz06-1301325388.cos.ap-chengdu.myqcloud.com/2022xiongmao/CommonAsset/img/home/下载.png"
                        alt=""
                    />
                </div>
            </div>
        </div>
        <!-- 主体区域 -->
        <div class="content">
            <div class="zhanwei"></div>
            <div class="title"></div>
            <!-- 目录按钮 -->
            <div class="directory" @click="goContent"></div>
            <!-- 百问 -->
            <div class="question" @click="goQuestion"></div>
        </div>
    </div>
</template>

<script>
import { reactive, toRefs } from "vue";
import {
    ElDropdown,
    ElButton,
    ElIcon,
    ElDropdownItem,
    ElDropdownMenu,
} from "element-plus";
import { useRouter } from "vue-router";
import axios from "axios";
import { useStore } from "vuex";
import Url from "../CommonAsset/js/config";
export default {
    components: {
        ElDropdown,
        ElButton,
        ElIcon,
        ElDropdownItem,
        ElDropdownMenu,
    },
    setup() {
        const router = useRouter();
        const state = reactive({
            // 语言数据
            dataItem: "",
            // 语言切换
            language: "",
            changeLanguage: "",
            warningtitle: false,
        });
        // 请求数据
        getDate();
        const store = useStore();
        function getDate() {
            axios.get(Url + "/api/data.php?action=getIndexData").then((res) => {
                // 获取VUEX数据
                // let lang = store.state.lang;
                let lang = localStorage.getItem("lang");
                // let lang =  "zh";
                state.language = res.data.language;
                state.dataItem = res.data;
                if (lang == undefined || lang == null) {
                    lang = "zh";
                }
                setTimeout(() => {
                    handleCommand(lang);
                }, 100);
            });
        }
        // // 选择语言
        function handleCommand(lang) {
            // VUEX
            // store.commit("LANG",lang)
            localStorage.setItem("lang", lang);
            state.changeLanguage = state.language[lang];
        }
        // 跳转
        function goContent() {
            router.push("/Content");
        }
        function goDownload() {
            router.push("/Download");
        }
        function goListen() {
            location.href = "https://test.lz06.com/EasyAR-WebAR/html/";
        }
        function goQuestion() {
            router.push("/Question");
        }
        // 提示
        function warning() {
            state.warningtitle = !state.warningtitle;
        }
        // 暴露出去
        return {
            ...toRefs(state),
            goContent,
            handleCommand,
            goDownload,
            warning,
            goListen,
            goQuestion,
        };
    },
};
</script>

<style scoped>
@import "../CommonAsset/css/animation.css";
#app {
    position: fixed;
    z-index: -1;
    width: 100%;
}
#app img {
    width: 100%;
}
.title {
    width: 251px;
    height: 180px;
    margin: 15% auto 0;
    background: url("https://lz06-1301325388.cos.ap-chengdu.myqcloud.com/2022xiongmao/CommonAsset/img/home/logo.png")
        no-repeat;
    background-size: 100%;
}
.directory {
    width: 141px;
    height: 117px;
    margin: 0 auto;
    margin-top: 12%;
    background: url("https://lz06-1301325388.cos.ap-chengdu.myqcloud.com/2022xiongmao/CommonAsset/img/home/进入目录.png")
        no-repeat;
}
.question {
    width: 177px;
    height: 117px;
    margin: 0 auto;
    margin-top: 10%;
    background: url("https://lz06-1301325388.cos.ap-chengdu.myqcloud.com/2022xiongmao/CommonAsset/img/home/熊猫百问.png")
        no-repeat;
}

.header {
    position: absolute;
    top: 7%;
    left: 7%;
}
.footer {
    width: 100%;
    height: 200px;
}
.zhanwei {
    position: relative;
    width: 100%;
    height: 50px;
}
.zhanwei > div {
    position: absolute;
    left: 10%;
    top: 90%;
    width: 29px;
    height: 28px;
}
.warningTitle {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 284px;
    height: 425px;
    background: url("https://lz06-1301325388.cos.ap-chengdu.myqcloud.com/2022xiongmao/CommonAsset/img/home/弹出框.png");
    z-index: 998;
}
.warnText {
    margin-top: 15%;
    font-size: 20px;
    text-indent: 2em;
    font-family: "宋体";
}
.cancel {
    position: relative;
    top: -4%;
    width: 29px;
    height: 28px;
    margin-left: 95%;
}
.warningContent {
    width: 95%;
    height: 100%;
    margin: 1% auto 0;
}
.goDownload {
    margin: 20% auto 0;
    width: 100px;
    height: 30px;
}
[v-cloak] {
    display: none;
}
</style>
