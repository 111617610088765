<template>
  <div class="father">
      <div @click="back" class="back"></div>
      <div class="backImage">
      </div>
      <div class="tupian_zh">
        <img src="https://lz06-1301325388.cos.ap-chengdu.myqcloud.com/2022xiongmao/CommonAsset/img/熊猫图上.png">
      </div>
      <div id="next"> 
        <video
          :src=url
          controls
        ></video>
      </div>
      <div class="tupian_en">
        <img src="https://lz06-1301325388.cos.ap-chengdu.myqcloud.com/2022xiongmao/CommonAsset/img/熊猫图英文.png">
      </div>
    </div>
</template>

<script>
import {  reactive, toRefs,onMounted } from "vue";
import { useStore } from "vuex";
import { useRouter,useRoute } from "vue-router";
export default {
  setup() {
    const router = useRouter();
    const route = useRoute();
    const state = reactive({
      url:""
    })
     // 返回
    function back() {
      router.go(-1);
    }
    onMounted(()=>{
        state.url = route.query.id
    })
    return {
      back,
      ...toRefs(state)
    };
  },
};
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
}
.father {
  position: relative;
}
.backImage {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}
.backImage > img {
  width: 100%;
  height: 100%;
}
#next {
  width: 98vw;
  height: 26vh;
  position: absolute;
  top: 10%;
  left: 1%;
}
.back {
  position: absolute;
  top: 2%;
  left: 5%;
  width: 28px;
  height: 28px;
  z-index: 2;
  background: url("https://lz06-1301325388.cos.ap-chengdu.myqcloud.com/2022xiongmao/CommonAsset/img/content/返回.png") no-repeat;
}
#next > video {
  width: 100%;
}
.tupian_zh {
  display: none;
  position: absolute;
  top: 15%;
  left: 50%;
  transform: translateX(-50%);
  width: 241px;
  height: 114px;
}
.tupian_en {
  display: none;
  position: absolute;
  top: 70%;
  left: 50%;
  transform: translateX(-50%);
  width: 248px;
  height: 77px;
}
</style>
