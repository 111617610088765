<template>
    <div id="app" v-cloak>
        <!-- 返回 -->
        <div class="back" @click="back"></div>
        <!-- 头部 -->
        <div class="header">
            <!-- 语言修改 -->
            <div class="languageChange">
                <el-dropdown @command="handleCommand" size="small">
                    <el-button type="primary" size="small">
                        {{ changeLanguage }}&nbsp;
                        <el-icon class="el-icon--right">
                            <svg
                                t="1641818630955"
                                class="icon"
                                viewBox="0 0 1024 1024"
                                version="1.1"
                                xmlns="http://www.w3.org/2000/svg"
                                p-id="2112"
                                width="16"
                                height="16"
                            >
                                <path
                                    d="M966.4 323.2c-9.6-9.6-25.6-9.6-35.2 0l-416 416-425.6-416c-9.6-9.6-25.6-9.6-35.2 0-9.6 9.6-9.6 25.6 0 35.2l441.6 432c9.6 9.6 25.6 9.6 35.2 0l435.2-432C976 345.6 976 332.8 966.4 323.2z"
                                    p-id="2113"
                                    fill="#ffffff"
                                ></path>
                            </svg>
                        </el-icon>
                    </el-button>
                    <template #dropdown>
                        <el-dropdown-menu>
                            <el-dropdown-item
                                v-for="(item, key) in dataItem.language"
                                :key="key"
                                :command="key"
                            >
                                {{ item }}
                            </el-dropdown-item>
                        </el-dropdown-menu>
                    </template>
                </el-dropdown>
            </div>
        </div>
        <!-- 头部 -->
        <div class="headers">
            <div class="title"></div>
            <div class="bird"></div>
        </div>
        <!-- list -->
        <ul class="content">
            <li
                v-for="(item, index) in common"
                :key="index"
                :ref="list"
                @click="goRead(item.id)"
            >
                <div class="title_left" :ref="left">{{ item.number }}</div>
                <div class="title_right">
                    <div class="right_title" :ref="right">{{ item.title }}</div>
                    <div class="yiwen" :class="{ ar }">{{ fanyi[index] }}</div>
                </div>
            </li>
        </ul>
    </div>
</template>

<script>
import { reactive, toRefs, nextTick, onMounted } from "vue";
import {
    ElDropdown,
    ElButton,
    ElIcon,
    ElDropdownItem,
    ElDropdownMenu,
} from "element-plus";
import { useRouter } from "vue-router";
import axios from "axios";
import { useStore } from "vuex";
import Url from "../CommonAsset/js/config";
export default {
    components: {
        ElDropdown,
        ElButton,
        ElIcon,
        ElDropdownItem,
        ElDropdownMenu,
    },
    setup() {
        // router
        const router = useRouter();
        // vuex
        const store = useStore();
        // 静态数据
        const state = reactive({
            common: [],
            fanyi: [],
            list: [],
            right: [],
            left: [],
            ar: false,
            language: "",
            changeLanguage: "",
            dataItem: "",
        });
        // 请求数据
        getLangDate();
        getDate();

        async function getDate() {
            let lang = localStorage.getItem("lang");
            const res = await axios.get(
                "https://lz06-1301325388.cos.ap-chengdu.myqcloud.com/temp/content.json"
            );
            state.common = res.data.common;
            if (lang == "zh") {
                state.fanyi = "";
                nextTick(() => {
                    lineHeight();
                });
            } else {
                NolineHeight();
                state.fanyi = res.data[lang];
                if (lang == "ar") {
                    state.ar = true;
                } else {
                    state.ar = false;
                }
            }
        }
        function getLangDate() {
            axios.get(Url + "/api/data.php?action=getIndexData").then((res) => {
                let lang = localStorage.getItem("lang");
                state.language = res.data.language;
                state.dataItem = res.data;
                if (lang == undefined || lang == null) {
                    lang = "zh";
                }
                // setTimeout(() => {
                handleCommand(lang);
                // }, 100);
            });
        }
        // // 选择语言
        function handleCommand(lang) {
            // VUEX
            // store.commit("LANG",lang)
            localStorage.setItem("lang", lang);
            getDate();
            state.changeLanguage = state.language[lang];
        }
        // 手动接收ref的数组，然后再使用
        const left = (el) => {
            state.left.push(el);
        };
        const right = (el) => {
            state.right.push(el);
        };
        const list = (el) => {
            state.list.push(el);
        };
        // 只有中文情况下设置行高
        function lineHeight() {
            for (let i = 0; i < state.left.length; i++) {
                state.left[i].style.lineHeight = "84px";
                state.right[i].style.lineHeight = "84px";
            }
        }
        function NolineHeight() {
            for (let i = 0; i < state.left.length; i++) {
                state.left[i].style.lineHeight = "42px";
                state.right[i].style.lineHeight = "42px";
            }
        }

        function lineColor() {
            setTimeout(() => {
                for (let i = 0; i < state.list.length; i++) {
                    if (i % 2 == 0) {
                        state.list[i].style.backgroundColor = "#f5f5f5";
                    }
                }
            }, 80);
        }
        function goRead(id) {
            localStorage.setItem("id", id);
            router.push({
                path: "/Read",
                query: {
                    id: id,
                },
            });
        }
        function back() {
            router.go(-1);
        }
        return {
            ...toRefs(state),
            back,
            goRead,
            list,
            left,
            right,
            handleCommand,
        };
    },
};
</script>

<style scoped>
[v-cloak] {
    display: none;
}
.headers {
    position: relative;
    display: flex;
    width: 100%;
    height: 14vh;
    border-bottom: 1px solid #adadad;
}
.title {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%);
    width: 271px;
    height: 38px;
    background: url("https://lz06-1301325388.cos.ap-chengdu.myqcloud.com/2022xiongmao/CommonAsset/img/content/大熊猫和他的伙伴们.png")
        no-repeat;
}
.bird {
    position: absolute;
    top: 60%;
    right: 2%;
    width: 42px;
    height: 42px;
    background: url("https://lz06-1301325388.cos.ap-chengdu.myqcloud.com/2022xiongmao/CommonAsset/img/content/鸟.png")
        no-repeat;
}
.back {
    position: absolute;
    top: 2%;
    left: 5%;
    width: 28px;
    height: 28px;
    z-index: 2;
    background: url("https://lz06-1301325388.cos.ap-chengdu.myqcloud.com/2022xiongmao/CommonAsset/img/content/返回.png")
        no-repeat;
}
.content {
    width: 100%;
}
.content > li {
    display: flex;
    width: 100%;
    height: 80px;
}
.title_left {
    width: 15%;
    color: #2d2d2d;
    text-align: right;
    font-size: 20px;
    font-weight: bold;
    line-height: 50px;
}
.title_right {
    height: 50px;
    margin-left: 3%;
    /* line-height: 50px; */
}
.right_title {
    color: #2d2d2d;
    font-size: 20px;
    height: 40px;
    line-height: 50px;
    font-weight: bold;
}
.yiwen {
    height: 5px;
    color: #007eff;
}
.ar {
    position: absolute;
    right: 2%;
}
.header {
    position: absolute;
    top: 2%;
    left: 74%;
    z-index: 6;
}
.content > li:nth-child(2n + 1) {
    background-color: #f5f5f5;
}
</style>
