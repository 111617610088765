<template>
    <div id="app" v-cloak>
        <div class="app" ref="lyric">
            <img
                class="background"
                src="https://lz06-1301325388.cos.ap-chengdu.myqcloud.com/2022xiongmao/CommonAsset/img/read/BG.png"
            />
            <div class="header">
                <div class="back" @click="back"></div>
                <div
                    class="panda_head"
                    @click="pandaHead"
                    ref="panda"
                    style="animation: move 2s infinite"
                ></div>
                <ul class="panda_list" v-show="panda_list">
                    <li v-for="(item, key) in readVideo" :key="key">
                        <div @click="goto_video(item.media_url)">
                            <span class="circle">{{ item.content }}</span>
                        </div>
                    </li>
                </ul>
                <!-- 标题 -->
                <div class="title">
                    <img :src="readImage" />
                </div>
            </div>

            <div style="width: 100%; height: 100px"></div>
            <div></div>
            <!-- 内容区 -->
            <div class="content">
                <!-- 课文部分 -->
                <div
                    class="lyrics"
                    :ref="TextDiv"
                    v-for="(item, index) in readTexts"
                    :key="index"
                >
                    <div
                        :ref="list"
                        v-for="(i, index) in item.lang"
                        :key="index"
                        :class="{ ar }"
                    >
                        <div>{{ i }}</div>
                    </div>
                </div>
            </div>
            <!-- 分割线 -->
            <!-- =================================================================================生词部分 -->
            <div class="segmentation"></div>
            <Shengci @close="closeRead" />
            <!-- 播放器 -->
            <div class="audioBox" ref="audioBox">
                <div @click="shouqi" class="shouqi">
                    <img
                        src="https://lz06-1301325388.cos.ap-chengdu.myqcloud.com/2022xiongmao/CommonAsset/img/read/三角向右.png"
                        v-show="shouQi"
                    />
                    <img
                        src="https://lz06-1301325388.cos.ap-chengdu.myqcloud.com/2022xiongmao/CommonAsset/img/read/三角向左.png"
                        v-show="!shouQi"
                    />
                </div>
                <ul class="audioPlayer">
                    <li @click="backMusic">
                        <img
                            src="https://lz06-1301325388.cos.ap-chengdu.myqcloud.com/2022xiongmao/CommonAsset/img/read/-5秒.png"
                        />
                    </li>
                    <li @click="play_music">
                        <img
                            src="https://lz06-1301325388.cos.ap-chengdu.myqcloud.com/2022xiongmao/CommonAsset/img/read/播放.png"
                            v-show="player"
                        />
                        <img
                            src="https://lz06-1301325388.cos.ap-chengdu.myqcloud.com/2022xiongmao/CommonAsset/img/read/暂停.png"
                            v-show="pause"
                        />
                    </li>
                    <li @click="fastForward">
                        <img
                            src="https://lz06-1301325388.cos.ap-chengdu.myqcloud.com/2022xiongmao/CommonAsset/img/read/+5秒.png"
                        />
                    </li>
                </ul>
            </div>
            <audio ref="readTextAudio" :src="TextAudio"></audio>
            <audio ref="readAudio"></audio>
        </div>
    </div>
</template>

<script>
import { reactive, toRefs, onMounted, ref } from "vue";

import { useRoute, useRouter } from "vue-router";
import axios from "axios";
import { useStore } from "vuex";
import Shengci from "../view/Shengci.vue";
import Url from "../CommonAsset/js/config";
export default {
    components: {
        Shengci,
    },
    setup() {
        // 接收用useRoute,发送用userRouter,区别在RRRRRRRR
        const route = useRoute();
        const router = useRouter();
        const store = useStore();
        let lang = localStorage.getItem("lang");
        let id = localStorage.getItem("id");
        let panda = ref(null);
        let readTextAudio = ref(null);
        let readAudio = ref(null);
        let PauseAudio = ref(null);

        // 视频模块+头部title
        const Video = reactive({
            TextUrl: "/api/data.php?action=getTextData&",
            readImage: "",
            panda_list: false,
            readVideo: [],
            readTexts: [],
            TextAudio: "",
            ar: false,
            readAudios: "",
        });
        // 课文板块请求数据

        getReadData();

        async function getReadData() {
            const res = await axios.get(
                Url + Video.TextUrl + `contentId=${id}&lang=${lang}&page=web`
            );
            Video.readVideo = res.data.readVideo;
            Video.readTexts = res.data.readTexts;
            Video.readImage = res.data.readImage;
            Video.TextAudio = res.data.readAudio;
        }
        /* 2022-9-7----------------------------------------------------------- */
        if (lang == "ar") {
            Video.ar = true;
        }
        // 视频列表
        function pandaHead() {
            Video.panda_list = !Video.panda_list;
            panda.value.style.animation = "";
            if (Video.panda_list == false) {
                panda.value.style.animation = "move 2s infinite";
            }
        }
        function goto_video(url) {
            store.commit("VIDEOURL", url);
            Video.panda_list = !Video.panda_list;
            panda.value.style.animation = "move 1.4s infinite";
            router.push("/Video");
        }
        // 课文部分
        const text = reactive({
            TextDiv: [],
            list: [],
        });
        let TextDiv = (el) => {
            text.TextDiv.push(el);
        };
        let list = (el) => {
            text.list.push(el);
        };
        setTimeout(() => {
            text.list[0].style.direction = "ltr";
        }, 500);
        // =====================================================================================================生词部分
        const word = reactive({
            player: true,
            pause: false,
        });
        // ================================================================播放器
        const player = reactive({
            shouQi: true,
            // 当前行
            lineNo: 0,
            // 开始滚动行
            Cpos: 1,
            //定时器
            timer: null,
            listChoose: [],
            music_point: [],
        });
        // 后退5秒
        function backMusic() {
            readTextAudio.value.currentTime =
                parseInt(readTextAudio.value.currentTime) - 5;
            let audio_current = readTextAudio.value.currentTime; //后退了5秒的播放时间点
            if (!word.player) {
                if (audio_current <= 0) {
                    audio_current = 0;
                }
                for (let i = 0; i < text.TextDiv.length; i++) {
                    text.TextDiv[i].style.color = "black";
                }
                if (lang !== "zh") {
                    for (let i = 0; i < player.listChoose.length; i++) {
                        player.listChoose[i].style.color = "#007eff";
                    }
                }
                for (let i = 0; i < player.music_point.length; i++) {
                    if (
                        parseInt(readTextAudio.value.currentTime) >= player.music_point[i]
                    ) {
                        player.lineNo = i;
                        lineHigh(); //高亮当前行
                    }
                }
                readTextAudio.value.play();
            }
        }
        //播放音乐
        function play_music() {
            readAudio.value.load();
            if (word.player) {
                readTextAudio.value.play();
                word.player = false;
                word.pause = true;
            } else {
                readTextAudio.value.pause();
                word.player = true;
                word.pause = false;
            }
            readTextAudio.value.addEventListener("timeupdate", () => {
                if (player.lineNo == Video.readTexts.length) return;
                const curTime = readTextAudio.value.currentTime;
                if (parseFloat(Video.readTexts[player.lineNo].currentTime) <= curTime) {
                    lineHigh(); //高亮当前行
                    player.lineNo++;
                }
            });
        }
        // 快进5秒
        function fastForward() {
            let currentTime = readTextAudio.value.currentTime;
            let zongshichang = parseFloat(readTextAudio.value.duration);
            if (!word.player) {
                readTextAudio.value.pause();
                let now = currentTime + 5;
                readTextAudio.value.currentTime = now;
                if (now >= zongshichang) {
                    replay();
                } else {
                    readTextAudio.value.play();
                }
            }
        }
        //音乐播放完成后的重置
        function replay() {
            readTextAudio.value.load();
            word.player = true;
            word.pause = false;
            text.TextDiv.forEach((element) => {
                element.style.color = "black";
            });
            if (lang !== "zh") {
                player.listChoose.forEach((e) => {
                    e.style.color = "#007eff";
                });
            }
            // 当前行
            player.lineNo = 0;
            // 开始滚动行
            player.Cpos = 1;
        }
        let lyric = ref();
        function lineHigh() {
            if (player.lineNo > 0) {
                text.TextDiv[player.lineNo - 1].style.color = "black";
                if (lang !== "zh") {
                    player.listChoose[player.lineNo - 1].style.color = "#007eff";
                }
                let top = 50 * player.lineNo;
                ScrollTop(lyric.value, top, 300);
            }
            text.TextDiv[player.lineNo].style.color = "#ffbb18";
            if (lang !== "zh") {
                player.listChoose[player.lineNo].style.color = "#ffbb18";
            }
        }
        //滚动动画
        function ScrollTop(targetElement, number = 0, time) {
            if (!time) {
                targetElement.scrollTop = number;
                return number;
            }
            const spacingTime = 3; // 设置循环的间隔时间  值越小消耗性能越高
            let spacingIndex = time / spacingTime; // 计算循环的次数
            let nowTop = targetElement.scrollTop; // 获取当前滚动条位置
            console.log(nowTop);
            let everTop = (number - nowTop) / spacingIndex; // 计算每次滑动的距离
            let scrollTimer = setInterval(() => {
                if (spacingIndex > 0) {
                    spacingIndex--;
                    ScrollTop(targetElement, (nowTop += everTop));
                } else {
                    clearInterval(scrollTimer); // 清除计时器
                }
            }, spacingTime);
        }
        // 收起播放条
        let audioBox = ref(null);
        function shouqi() {
            player.shouQi = !player.shouQi;
            if (!player.shouQi) {
                audioBox.value.style.animation = "shouqi 0.5s";
                audioBox.value.style.right = "-43%";
            } else {
                audioBox.value.style.animation = "lakai 0.5s";
                audioBox.value.style.right = "0%";
            }
        }
        // 存入标记点
        function pushPoint() {
            setTimeout(() => {
                for (let i = 0; i < Video.readTexts.length; i++) {
                    player.music_point.push(Video.readTexts[i].currentTime);
                }
            }, 300);
        }
        // 关闭阅读
        let closeRead = () => {
            readTextAudio.value.pause();
            word.player = true;
            word.pause = false;
        };
        onMounted(() => {
            setTimeout(() => {
                window.scrollTo(0, 0);
            }, 0);
            setTimeout(() => {
                if (lang !== "zh") {
                    player.listChoose = text.list.filter(function (_, i) {
                        return i % 2 == 1;
                    });
                }
            }, 300);
            readTextAudio.value.addEventListener("ended", () => {
                replay();
            });
            pushPoint();
        });
        // 返回
        function back() {
            router.go(-1);
        }
        return {
            ...toRefs(Video),
            back,
            goto_video,
            pandaHead,
            panda,
            ...toRefs(text),
            ...toRefs(word),
            ...toRefs(player),
            readTextAudio,
            readAudio,
            shouqi,
            audioBox,
            play_music,
            lyric,
            TextDiv,
            fastForward,
            backMusic,
            list,
            PauseAudio,
            Shengci,
            closeRead,
        };
    },
};
</script>

<style scoped>
@import "../CommonAsset/css/animation.css";
/* 隐藏滚动条 */
::-webkit-scrollbar {
    display: none;
}
.headers {
    position: absolute;
    right: 8%;
    top: 1%;
    z-index: 6;
}
.app {
    width: 100%;
    height: 812px;
    overflow-y: scroll;
}
.background {
    position: fixed;
    width: 100%;
    z-index: -1;
}
.header {
    position: fixed;
    width: 100%;
    height: 100px;
    z-index: 7;
    background: url("https://lz06-1301325388.cos.ap-chengdu.myqcloud.com/2022xiongmao/CommonAsset/img/read/BG100像素.png")
        no-repeat;
    background-size: 100%;
}
.back {
    position: absolute;
    top: 20%;
    left: 5%;
    width: 28px;
    height: 28px;
    z-index: 2;
    background: url("https://lz06-1301325388.cos.ap-chengdu.myqcloud.com/2022xiongmao/CommonAsset/img/content/返回.png")
        no-repeat;
}
.panda_head {
    display: none;
    position: absolute;
    top: 20%;
    right: 6%;
    width: 55px;
    height: 39px;
    z-index: 2;
    background: url("https://lz06-1301325388.cos.ap-chengdu.myqcloud.com/2022xiongmao/CommonAsset/img/read/信息按钮.png")
        no-repeat;
}
.title {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%);
    width: 286px;
    height: 45px;
}
.content {
    width: 90%;
    height: auto;
    margin: 3% auto;
    background-color: #fcfdeb;
    opacity: 0.8;
    border: 1px solid #cccccc;
    overflow-y: scroll;
}
.lyrics {
    width: 90%;
    margin: 0 auto;
    font-size: 17px;
}
.lyrics > div {
    margin-top: 5%;
}
.lyrics > div:nth-child(2) {
    margin-top: 0;
    color: #007eff;
}

.segmentation {
    width: 100%;
    height: 19px;
    margin: 3% auto;
    background: url("https://lz06-1301325388.cos.ap-chengdu.myqcloud.com/2022xiongmao/CommonAsset/img/read/分割竹子.png")
        no-repeat;
    background-size: 100%;
}
.panda_list {
    position: absolute;
    right: 1%;
    top: 60%;
    width: 70px;
    background-color: white;
    color: #ffbb18;
    text-align: center;
    border-radius: 7px;
    border: 1px solid #cccccc;
    z-index: 5;
}
.panda_list > li:nth-child(1) {
    margin-top: 10%;
}
.panda_list > li {
    position: relative;
    width: 90%;
    margin: 8% auto 0;
    height: 50px;
    border-bottom: 1px solid #cccccc;
}
.panda_list > li > div {
    position: absolute;
    top: 17%;
    left: 17%;
    width: 41px;
    height: 28px;
    line-height: 28px;

    color: #e42c0e;
    font-weight: bold;
    background: url("https://lz06-1301325388.cos.ap-chengdu.myqcloud.com/2022xiongmao/CommonAsset/img/read/影片.png");
}
.panda_list > li:nth-child(4) {
    border-bottom: 1px solid #ffffff;
}
.shouqi {
    width: 22px;
    height: 48px;
    line-height: 48px;
    text-align: center;
    position: absolute;
    top: 0%;
    left: 0%;
}
.circle {
    position: absolute;
    top: 22%;
    left: 32%;
    display: block;
    border-radius: 30px;
    background-color: #fce2b8;
    border: 1px solid #e42c0e;
    width: 15px;
    height: 15px;
    line-height: 15px;
    text-align: center;
    color: #e42c0e;
    font-size: 10px;
}

.audioBox {
    position: fixed;
    top: 85%;
    right: 0%;
    width: 200px;
    height: 48px;
    background-color: #080808;
    border-radius: 15px 0 0 15px;
    z-index: 9991;
}
.audioPlayer {
    display: flex;
    width: 75%;
    margin-top: 4%;
    margin-left: 20%;
    justify-content: space-between;
    align-items: center;
}
.ar {
    direction: rtl;
}
[v-cloak] {
    display: none;
}
.lyrics > div:nth-child(1) {
    direction: ltr;
}
</style>
