<template>
  <div id="app" v-cloak>
    <!-- 返回 -->
    <div class="back" @click="back"></div>
    <!-- 头部 -->
    <div class="header">
      <div class="title"></div>
    </div>
    <!-- list -->
    <ul class="content">
      <li v-for="(item, index) in list" :key="index"  @click="goRead(item.video)">
        {{index+1}}.&nbsp;&nbsp;{{item.name}}
      </li>
    </ul>
  </div>
</template>

<script>
import { reactive, toRefs} from "vue";
import { useRouter } from "vue-router";
import axios from "axios";

import Url from '../CommonAsset/js/config';
export default {
  setup() {
    // router
    const router = useRouter();
  
    // 静态数据
    const state = reactive({
     list:[],
    });
    // 请求数据
    getDate();
   
  async function getDate() {
      // let lang = store.state.lang;
      const res = await axios.get(Url+"/api/data.php?action=getAllVideo");
      state.list = res.data
    }
 
    function goRead(id) {
      router.push({
        path: "/Video",
        query: {
          id: id,
        },
      });
    }
    function back() {
      router.go(-1);
    }
    return {
      ...toRefs(state),
      back,
      goRead,

    };
  },
};
</script>

<style scoped>
[v-cloak] {
  display: none;
}
.header {
  position: relative;
  display: flex;
  width: 100%;
  height: 14vh;
  border-bottom: 1px solid #adadad;
}
.title {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translateX(-50%);
  width: 298px;
  height: 48px;
  background: url("https://lz06-1301325388.cos.ap-chengdu.myqcloud.com/2022xiongmao/CommonAsset/img/content/抬头.png") no-repeat;
  background-size: 100%;
}
.bird {
  position: absolute;
  top: 63%;
  right: 2%;
  width: 55px;
  height: 39px;
  background: url("https://lz06-1301325388.cos.ap-chengdu.myqcloud.com/2022xiongmao/CommonAsset/img/read/信息按钮.png") no-repeat;
}
.back {
  position: absolute;
  top: 2%;
  left: 5%;
  width: 28px;
  height: 28px;
  z-index: 2;
  background: url("https://lz06-1301325388.cos.ap-chengdu.myqcloud.com/2022xiongmao/CommonAsset/img/content/返回.png") no-repeat;
}
.content {
  width: 100%;
}
.content > li {
  width: 100%;
  height: 80px;
  line-height: 80px;
  font-size:18px;
  text-indent: 1.2em;
  font-weight: bold;
}
.title_left {
  width: 15%;
  color: #2d2d2d;
  text-align: right;
  font-size: 20px;
  font-weight: bold;
  line-height: 50px;
}
.title_right {
  height: 50px;
  margin-left: 3%;
}
.right_title {
  color: #2d2d2d;
  font-size: 20px;
  height: 40px;
  line-height: 50px;
  font-weight: bold;
}
.yiwen {
  
  height: 5px;
  color: #007eff;
}
.ar{
  position: absolute;
  right: 2%;
}
.content >li:nth-child(2n+1){
  background-color:#f5f5f5;
}
</style>
